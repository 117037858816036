import React, { useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice"

import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

const Container = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Importação assíncrona
        const { default: fetchProducts } = await import('./data/queries/products-queries');
        const products = await fetchProducts(); // Chama a função assíncrona
        store.dispatch(setProducts(products));
      } catch (error) {
        // Lidar com erros conforme necessário
        alert("Erro ao buscar produtos. Consulte o console para obter mais detalhes.");
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Provider store={store}>
      <PersistProvider>
        <App />
      </PersistProvider>
    </Provider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Container />);

